import React from 'react'
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { RiInstagramFill,   } from 'react-icons/ri';
import { BsFacebook, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import ScrollToTop from 'react-scroll-to-top';
import UperFooter from './UperFooter';
import { useNavigate } from 'react-router-dom';



const Footer = ({loader}) => {

    const navigate = useNavigate()
    const chengRouts=(routs)=>{
        loader(true)
        setTimeout(()=>{
          navigate(routs)
        },500) 
      }
  return (
    <div style={{background:'#0A0B18',marginTop:'-2px'}}>
         <ScrollToTop  top="20" width='20'   /> 
        <br />
        <div class='d-flex align-items-center col-11 m-auto'>
            <div style={{width:'10px',height:'8px',borderRadius:'20px',background:'white'}}></div>
            <div   style={{width:'100%',height:'1px',border:'0.5px solid #656565'}}></div>
        </div>
        <br />

        
        <div class='col-11 m-auto row gap-md-0 gap-4'>
            <div class='col-md-5 text-start'>
                <p class='fs-1 fw-bold' style={{color:'#333B8F'}}>Connect</p>
                <p class='fs-1 fw-bold' style={{color:'#66707A'}}>With Us</p>
                <p class='display-2 fw-bold' style={{color:'#66707A'}}><MdSubdirectoryArrowRight/></p>
                <p class='text-white mt-3'>Or Email us at</p>
                <p style={{color:'#66707A'}}> <a href='mailto:support@arthro3d.com' style={{color:'#66707A',textDecoration:'none'}}>support@arthro3d.com</a></p>
            </div>
            <div class='col-md-7 row gap-sm-0 gap-4'>
                <div class='col-sm-6 text-start'>
                    <p class='fs-5 text-white'>Menu</p>
                  
                    <p class='mt-3' style={{color:'#66707A',cursor:'pointer'}} onClick={()=>chengRouts('/')}>Home</p>
                    <p class='mt-2' style={{color:'#66707A',cursor:'pointer'}} onClick={()=>chengRouts('/about')}>About</p> 
                    <p class='mt-2' style={{color:'#66707A',cursor:'pointer'}} onClick={()=>chengRouts('/arthro3robo-mr')}>Arthro3Robo MR</p>
                    <p class='mt-2' style={{color:'#66707A',cursor:'pointer'}} onClick={()=>chengRouts('/arthro3robo-plan')}>Arthro3Robo Plan</p>
                    <p class='mt-2' style={{color:'#66707A',cursor:'pointer'}} onClick={()=>chengRouts('/faqs')}>FAQs</p>
                    <p class='mt-2' style={{color:'#66707A',cursor:'pointer'}} onClick={()=>chengRouts('/contact')}>Contact us</p>

                </div>
                <div class='col-sm-6 text-start'>
                    <p class='fs-5 text-white'>Office</p>
                    
                    <p class='mt-3'style={{color:'#66707A'}}>19 Shantinagar Society, <br /> Ashram Rd,  Nava Vadaj, <br /> Ahmedabad   </p>
                  
                    <p  class='mt-2' style={{color:'#66707A'}}>Phone : <a href="tel:+9173914080" style={{color:'#66707A',textDecoration:'none'}}>+91 9173914080</a></p> 
                     <div class='d-flex gap-3 fs-2 mt-3' style={{color:'#333B8F'}}>
                        <a href='https://www.linkedin.com/in/arthro3d-llp-739a372a4/' target='_balnck'><BsLinkedin/></a>
                        <a href='https://www.facebook.com/profile.php?id=100063868277093' target='_balnck'><BsFacebook/></a>
                        <a href='https://www.youtube.com/@arthro3d594' target='_balnck'><BsYoutube/></a>
                        {/* <a href='/' target='_balnck'><RiInstagramFill /></a> */}
                        {/* <a href='/' target='_balnck'><BsTwitter/></a> */}


                     </div>

                </div> 
            </div>
        </div>
        <br />
        <div class='col-11 m-auto d-flex align-items-center justify-content-between mt-3 flex-wrap text-start gap-3'>
            <div>
                <p style={{color:'#66707A'}}>© 2024 Arthro 3D LLP All rights reserved. <br />  Developed by : <span style={{color: 'rgb(128, 192, 77)', fontWeight: 'bold'}}> <a href="https://hi-labsolution.com/" target="_blank"><img src="https://hi-labsolution.com/hlogo.png" alt="Hi-Lab Solution"/></a> Hi Lab Solution </span></p>
            </div>
            <div>
                <p style={{color:'#66707A'}}>Terms & Conditions</p> 
            </div>
            <div>
                <p style={{color:'#66707A'}}>Privacy Policy</p> 
            </div>
        </div>
        <br /><br />
    </div>
  )
}

export default Footer