import React, { useEffect } from 'react'
import './style.css'
import AOS from "aos";
import "aos/dist/aos.css";

const UperFooter = ({data}) => {

  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  return (
    <div>
        <br /><br />
        <div class='col-11 m-auto p-3  uperFooterCircle' style={{   }} data-aos="zoom-in-up">
          <br />
                <p class='display-3 text-white mt-4 fw-bold col-10 m-auto'>Want to know more ?</p>
                <div class='buttonDiv m-auto mt-3' onClick={()=>data(true)}>
                    <div class='button'>Register With Us</div>
                    <div class='button-clip'>Register With Us</div>  
                </div>
                <br /><br /> 
        </div>
        <br /><br />

    </div>
  )
}

export default UperFooter