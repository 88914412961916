import React, { useEffect, useRef, useState } from 'react'

const PeopelClip = () => {
    const [lastScrollY, setLastScrollY] = useState(0); 
    const [wordclipPath1,setWordclipPath1] = useState()
    const [popele, setPopele] = useState()

  

  const container1 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 1.0,
    });

    const target = document.querySelector("[data-scroll-research]");
    observer.observe(target);

    function callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.addEventListener("scroll", onScroll);
        } else {
          // window.removeEventListener("scroll", onScroll);
        }
      });
    }

    const update = () => {
      if(!container1.current) return
      const bounds1 = container1.current.getBoundingClientRect(); 
      const percentage1 = Math.trunc(((bounds1.y*12/bounds1.height))*4);  
      const percentage2 = Math.trunc(((bounds1.y*12/bounds1.height))*6);  

      setWordclipPath1( `inset(0 ${percentage1}% 0  0 )`) 
      setPopele(`${percentage2}%`) 
    };

    const requestTick = () => { 
        window.requestAnimationFrame(update); 
    };

    const onScroll = () => {
      setLastScrollY(window.scrollY);
      requestTick();
    };

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div class='popele' style={{ position: 'relative',overflowX:'hidden' }} ref={container1} data-scroll-research>
      
        <div  class='popele1'> 
        </div>
        <div className='popele2 ' style={{ position: 'absolute', bottom: '0',clipPath: wordclipPath1 }}>
          <img src={require('../../images/walk_peopel2.webp')}   alt="" /> 
        </div>
        <div class='popeleMan' style={{position:'absolute',right:`${popele}` }}>
          <img src={require('../../images/people.png')} style={{width:'100%'}} alt="" />
        </div>
  </div>
  )
}

export default PeopelClip