import React, { useEffect, useRef, useState } from 'react'

const ClipPath = () => {
  const [lastScrollY, setLastScrollY] = useState(0); 
  const [wordclipPath1,setWordclipPath1] = useState()
  const [wordclipPath2,setWordclipPath2] = useState()
  const [wordclipPath3,setWordclipPath3] = useState()
  const [wordclipPath4,setWordclipPath4] = useState()
  const [wordclipPath5,setWordclipPath5] = useState()
  // const [wordclipPath6,setWordclipPath6] = useState()
  // const [wordclipPath7,setWordclipPath7] = useState()
  // const [wordclipPath8,setWordclipPath8] = useState()
  // const [wordclipPath9,setWordclipPath9] = useState()

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  }); 

  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }; 
  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    window.addEventListener('load', updateWindowSize);

    return () => {
      window.removeEventListener('resize', updateWindowSize);
      window.removeEventListener('load', updateWindowSize);
    };
  }, []);


  const container1 = useRef(null);
  const container2 = useRef(null);
  const container3 = useRef(null);
  const container4 = useRef(null);
  const container5 = useRef(null);
  const container6 = useRef(null);
  // const container7 = useRef(null);
  // const container8 = useRef(null);
  // const container9 = useRef(null);



 


  useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 1.0,
    });

    const target = document.querySelector("[data-scroll-research]");
    observer.observe(target);

    function callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.addEventListener("scroll", onScroll);
        } else {
          // window.removeEventListener("scroll", onScroll);
        }
      });
    }

    const update = () => {
      if(!container1.current || !container2.current || !container3.current || !container4.current || !container5.current){
        return
      }
      const bounds1 = container1.current.getBoundingClientRect();
      const bounds2 = container2.current.getBoundingClientRect();
      const bounds3 = container3.current.getBoundingClientRect();
      const bounds4 = container4.current.getBoundingClientRect();
      const bounds5 = container5.current.getBoundingClientRect();
      // const bounds6 = container6.current.getBoundingClientRect();
      // const bounds7 = container7.current.getBoundingClientRect();
      // const bounds8 = container8.current.getBoundingClientRect();
      // const bounds9 = container9.current.getBoundingClientRect();


      const percentage1 = Math.trunc(((bounds1.y*`${windowSize.width > 770 ? 8 : 2.5}`/bounds1.height)*2)); 
      const percentage2 = Math.trunc(((bounds2.y*`${windowSize.width > 770 ? 8.1 : 2.7}`/bounds2.height)*2)); 
      const percentage3 = Math.trunc(((bounds3.y*`${windowSize.width > 770 ? 8.3 : 2.9}`/bounds3.height)*2)); 
      const percentage4 = Math.trunc(((bounds4.y*`${windowSize.width > 770 ? 8.5 : 3.1}`/bounds4.height)*2)); 
      const percentage5 = Math.trunc(((bounds5.y*`${windowSize.width > 770 ? 8.7 : 3.3}`/bounds5.height)*2)); 
      // const percentage6 = Math.trunc(((bounds6.y*8.6/bounds6.height)*2)); 
      // const percentage7 = Math.trunc(((bounds7.y*8.7/bounds7.height)*2)); 
      // const percentage8 = Math.trunc(((bounds8.y*8.8/bounds8.height)*2)); 
      // const percentage9 = Math.trunc(((bounds9.y*8.9/bounds9.height)*2)); 

     


 



      setWordclipPath1( `inset(0 ${percentage1}% 0  0 )`) 
      setWordclipPath2( `inset(0 ${percentage2}% 0  0 )`) 
      setWordclipPath3( `inset(0 ${percentage3}% 0  0 )`) 
      setWordclipPath4( `inset(0 ${percentage4}% 0  0 )`) 
      setWordclipPath5( `inset(0 ${percentage5}% 0  0 )`) 
      // setWordclipPath6( `inset(0 ${percentage6}% 0  0 )`) 
      // setWordclipPath7( `inset(0 ${percentage7}% 0  0 )`) 
      // setWordclipPath8( `inset(0 ${percentage8 }% 0  0 )`) 
      // setWordclipPath9( `inset(0 ${percentage9 }% 0  0 )`) 

      

    };

    const requestTick = () => { 
        window.requestAnimationFrame(update); 
    };

    const onScroll = () => {
      setLastScrollY(window.scrollY);
      requestTick();
    };

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);


 
  return (
    
    <div class='col-11 m-auto'>
    <div style={{ position: 'relative' }} ref={container1} data-scroll-research>
      <div className='text-start w-100 w-100 '>
        <p className='clpiText fw-normal' style={{color:"#3C4073"}}>Pre-surgical</p>
      </div>
      <div className='wordColor1 ' style={{ position: 'absolute', top: '0',left:'0',clipPath: wordclipPath1 }}>
        <p className='clpiText  fw-normal ' style={{color:'#D5D8FF'}}>Pre-surgical</p>
      </div>
    </div>

    <div style={{ position: 'relative' }} ref={container2} data-scroll-research>
      <div className='text-start w-100 '>
        <p className='clpiText fw-normal' style={{color:"#3C4073"}}>planning on Mixed </p>
      </div>
      <div className='wordColor1' style={{ position: 'absolute', top: '0',clipPath: wordclipPath2 }}>
        <p className='clpiText  fw-normal ' style={{color:'#D5D8FF'}}>planning on Mixed </p>
      </div>
    </div>

    <div style={{ position: 'relative' }} ref={container3} data-scroll-research>
      <div className='text-start w-100 '>
        <p className='clpiText fw-normal' style={{color:"#3C4073"}}>Reality platform</p>
      </div>
      <div className='wordColor1' style={{ position: 'absolute', top: '0',clipPath: wordclipPath3 }}>
        <p className='clpiText  fw-normal ' style={{color:'#D5D8FF'}}>Reality platform</p>
      </div>
    </div>

    <div style={{ position: 'relative' }} ref={container4} data-scroll-research>
      <div className='text-start w-100 '>
        <p className='clpiText fw-normal' style={{color:"#3C4073"}}>with Smart Glass </p>
      </div>
      <div className='wordColor1' style={{ position: 'absolute', top: '0',clipPath: wordclipPath4 }}>
        <p className='clpiText  fw-normal ' style={{color:'#D5D8FF'}}>with Smart Glass </p>
      </div>
    </div>

    <div style={{ position: 'relative' }} ref={container5} data-scroll-research>
      <div className='text-start w-100 '>
        <p className='clpiText fw-normal' style={{color:"#3C4073"}}>technology</p>
      </div>
      <div className='wordColor1' style={{ position: 'absolute', top: '0',clipPath: wordclipPath5 }}>
        <p className='clpiText  fw-normal ' style={{color:'#D5D8FF'}}>technology</p>
      </div>
    </div> 
  </div>
  )
}

export default ClipPath