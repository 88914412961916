import React, { useEffect, useState } from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom';
const Navbar = ({data,loader}) => {
    const [isActive, setIsActive] = useState(false); 
    
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
 
  
  
    const updateWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateWindowSize);
      window.addEventListener('load', updateWindowSize);
  
      return () => {
        window.removeEventListener('resize', updateWindowSize);
        window.removeEventListener('load', updateWindowSize);
      };
    }, []); 

    useEffect(() => {
        const nav = document.querySelector(".navbarMain");
        let lastScrollY = window.scrollY;
        if(windowSize.width > '990'){
          nav.classList.add("navbarMain--hidden");
        }
        window.addEventListener("scroll", () => {
          if (lastScrollY < window.scrollY) {
            nav.classList.add("navbarMain--hidden");
          } else {
            nav.classList.remove("navbarMain--hidden");
          }
          lastScrollY = window.scrollY;
        });
      });

    useEffect(()=>{
        const nav = document.querySelector('.menus')
        nav.style.transition ="0.5s"
        nav.style.clipPath = 'inset(0 0 0 100%)'
        if(isActive){
        nav.style.transition ="1s" 
        nav.style.clipPath = 'inset(0 0 0 0)' 
        } 
      },[isActive])

    const navigate = useNavigate()

    const movePage = (path)=>{
        setIsActive(false)
        loader(true) 
        setTimeout(()=>{ 
        navigate(path)
        },500) 
      }

      const chengRouts=(routs)=>{
        loader(true)
        setTimeout(()=>{
          navigate(routs)
        },500) 
      }

      const media = () => {
        setIsActive(false) 
        window.open("https://arthro3d.online/media/", "_blank")
      }

  return (
    <div class='navbarMain'>
        <div class='mainNav col-11 m-auto d-flex align-items-center justify-content-between  p-2 my-3'>
            <div class='d-flex align-items-center'>
                <div style={{width:'80px',height:'45px',marginLeft:'20%'}}>
                    <img onClick={()=>chengRouts('./')}  src={require('../images/artho-logo.png')} style={{width:'100%',height:'100%',cursor:'pointer'}} alt="" />
                </div>
            </div>
            <div class='navPc'>
                 <div class='d-flex align-items-center  '>
                    <div class='d-flex gap-4'>
                        <p class='' style={{cursor:'pointer',color:'#66707A',fontSize:'16px'}} onClick={()=>chengRouts('./')} >Home</p>
                        <p class='' style={{cursor:'pointer',color:'#66707A',fontSize:'16px'}} onClick={()=>chengRouts('./about')} >About</p>
                        <p class='' style={{cursor:'pointer',color:'#66707A',fontSize:'16px'}} onClick={()=>chengRouts('./arthro3robo-mr')}>Arthro3Robo MR</p>
                        <p class='' style={{cursor:'pointer',color:'#66707A',fontSize:'16px'}} onClick={()=>chengRouts('./arthro3robo-plan')}>Arthro3Robo Plan</p>
                        <p class='' style={{cursor:'pointer',color:'#66707A',fontSize:'16px'}} onClick={()=>chengRouts('./faqs')} >FAQs</p>
                        <p class='' style={{cursor:'pointer',color:'#66707A',fontSize:'16px'}}><a style={{textDecoration:'none',color:'#66707A'}} href='https://arthro3d.online/media/' target='_blanck'>Media</a></p> 
                        <p class='' style={{cursor:'pointer',color:'#66707A',fontSize:'16px'}} onClick={()=>chengRouts('./contact')}>Contact Us</p>  
                    </div> 
                </div>
            </div>

            <div class='navPc'>
                <div class='d-flex align-items-center px-2 '> 
                    <div class='buttonDiv' onClick={()=>chengRouts('/surgeon-center')}>
                      <div class='button'>Surgeon's Center</div>
                      <div class='button-clip'>Surgeon's Center</div>  
                    </div>
                </div>
            </div> 

            <div class='navMobile'>
                <div class={isActive ? 'hamburger is-active' : 'hamburger'} id="hamburger" onClick={()=> setIsActive(!isActive)} >
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                  </div> 
            </div>
        </div>

        <div class='menus d-flex justify-content-center align-items-center ' style={{width:'100%',height:'110vh',position:'fixed',top:'0',right:'0', zIndex: '1',background:'linear-gradient(to left , rgb(207, 207, 207), rgb(230, 230, 230),rgb(240, 240, 240)'}}>
            <div class='d-grid gap-3 '>
                  <p style={{cursor:'pointer',fontWeight:'600',fontSize:'25px',color:'#66707A'}} onClick={()=>movePage('/')}>Home</p>
                  <p style={{cursor:'pointer',fontWeight:'600',fontSize:'25px',color:'#66707A'}} onClick={()=>movePage('/about')}>About</p>
                  <p style={{cursor:'pointer',fontWeight:'600',fontSize:'25px',color:'#66707A'}} onClick={()=>movePage('/arthro3robo-mr')}>Arthro3Robo MR</p>
                  <p style={{cursor:'pointer',fontWeight:'600',fontSize:'25px',color:'#66707A'}} onClick={()=>movePage('/arthro3robo-plan')}>Arthro3Robo Plan</p>
                  <p style={{cursor:'pointer',fontWeight:'600',fontSize:'25px',color:'#66707A'}} onClick={()=>movePage('/faqs')}>FAQ</p> 
                  <p style={{cursor:'pointer',fontWeight:'600',fontSize:'25px',color:'#66707A'}} onClick={media}>Media</p>
                  <p style={{cursor:'pointer',fontWeight:'600',fontSize:'25px',color:'#66707A'}} onClick={()=>movePage('/contact')}>Contact Us</p>
               

                <div class='buttonDiv m-auto' onClick={()=>movePage('/surgeon-center')}>
                      <div class='button'>Surgeon's Center</div>
                      <div class='button-clip'>Surgeon's Center</div>  
                </div>
                    
            </div>
        </div>
        
    </div>
  )
}

export default Navbar