import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";

const TheProcess = () => {

    const [marketApproach, setMarketApproach] = useState('')

    useEffect(() => {
        AOS.init({ duration: 1500 });
      });
  return (
    <div style={{overflow:'hidden'}}>
        <div class='row gap-lg-0 gap-4'>
            <div class='col-lg-4 col-11 m-auto mt-0  d-flex align-items-start'>
                <div class='text-start col-11 m-auto mt-5'>
                    <p class='display-2 fw-medium text-white' data-aos="fade-up">The </p>
                    <p class='display-2 fw-medium text-white' data-aos="fade-up">Process</p>  
                </div> 
            </div>

            <div class='col-lg-8 col-11 row m-auto'> 

                 <div class='row col-11 m-auto gap-sm-0 gap-3 my-2 mt-5' data-aos="fade-up" style={{position:'relative',cursor:'pointer'}} onMouseEnter={()=>setMarketApproach('market1')} onMouseLeave={()=>setMarketApproach('')}>
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p class='fs-3'>CT Scan of Knees</p>
                    </div> 
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p>CT scan center uploads the RAW DiCom files to Arthro3D Server, without processing or reporting.</p>
                    </div> 
                    { marketApproach != "market1" ? "" :
                    <div style={{position:'absolute',top:'-50px',left:'30%',width:'180px',height:'150px'}}>
                            <img src={require('../../images/ap1.jpg')}  style={{width:'100%',height:'100%',borderRadius:'20px'}} alt="" />
                    </div>
                    }

                </div>

                <hr class='text-white m-0 p-0 my-3' style={{color:'white'}} data-aos="fade-up" />

                <div class='row col-11 m-auto gap-sm-0 gap-3 my-2' data-aos="fade-up" style={{position:'relative',cursor:'pointer'}} onMouseEnter={()=>setMarketApproach('market2')} onMouseLeave={()=>setMarketApproach('')}>
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p class='fs-3'>3D Bone Modeling</p>
                    </div> 
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p>3D Bone is created from the uploaded CT scan files.</p>
                    </div> 
                    { marketApproach != "market2" ? "" :
                    <div style={{position:'absolute',top:'-50px',left:'30%',width:'180px',height:'150px'}}>
                            <img src={require('../../images/ap2.jpg')}  style={{width:'100%',height:'100%',borderRadius:'20px'}} alt="" />
                    </div>
                    }

                </div>

                <hr class='text-white m-0 p-0 my-3' style={{color:'white'}} data-aos="fade-up" />

                <div class='row col-11 m-auto gap-sm-0 gap-3 my-2  ' data-aos="fade-up" style={{position:'relative',cursor:'pointer'}} onMouseEnter={()=>setMarketApproach('market3')} onMouseLeave={()=>setMarketApproach('')}>
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p class='fs-3'>Surgical Landmarks with Kne3Wiz</p>
                    </div> 
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p>Surgical landmarks are selected.</p>
                    </div> 
                    { marketApproach != "market3" ? "" :

                    <div style={{position:'absolute',top:'-50px',left:'30%',width:'180px',height:'150px'}}>
                            <img src={require('../../images/ap3.jpg')}  style={{width:'100%',height:'100%',borderRadius:'20px'}} alt="" />
                    </div>
                    }
                </div>

                <hr class='text-white m-0 p-0 my-3' style={{color:'white'}} data-aos="fade-up"/>

                <div class='row col-11 m-auto gap-sm-0 gap-3  my-2  ' data-aos="fade-up" style={{position:'relative',cursor:'pointer'}} onMouseEnter={()=>setMarketApproach('market4')} onMouseLeave={()=>setMarketApproach('')}>
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p class='fs-3'>Perfect Match of implant is obtained for the knee</p>
                    </div> 
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p class=''>Above created 3D Bone Model and Landmarks can be now seen on the surgeon’s dashboard.</p>
                    </div> 
                    { marketApproach != "market4" ? "" :
                    <div style={{position:'absolute',top:'-50px',left:'30%',width:'180px',height:'150px'}}>
                            <img src={require('../../images/ap4.jpg')}  style={{width:'100%',height:'100%',borderRadius:'20px'}} alt="" />
                    </div>
                    }
                </div>
                 

                <hr class='text-white m-0 p-0 my-3' style={{color:'white'}} data-aos="fade-up" />

                <div class='row col-11 m-auto gap-sm-0 gap-3 my-2 ' data-aos="fade-up" style={{position:'relative',cursor:'pointer'}} onMouseEnter={()=>setMarketApproach('market5')} onMouseLeave={()=>setMarketApproach('')}>
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p class='fs-3'>Surgical Planning is complete </p>
                    </div> 
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p>The surgeon can now perform Robotic 3D planning without the pressures of the operation theatre....</p>
                    </div> 
                    { marketApproach != "market5" ? "" :

                    <div style={{position:'absolute',top:'-50px',left:'30%',width:'180px',height:'150px'}}>
                            <img src={require('../../images/ap5.jpg')}  style={{width:'100%',height:'100%',borderRadius:'20px'}} alt="" />
                    </div>
                    }
                </div>

                <hr class='text-white m-0 p-0 my-3' style={{color:'white'}} data-aos="fade-up" />

                <div class='row col-11 m-auto gap-sm-0 gap-3 my-2 mb-5 ' data-aos="fade-up" style={{position:'relative',cursor:'pointer'}} onMouseEnter={()=>setMarketApproach('market6')} onMouseLeave={()=>setMarketApproach('')}>
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p class='fs-3'>Time is most essential</p>
                    </div> 
                    <div class='col-md-6 text-start d-flex align-items-center text-white'>
                        <p>  Since the entire process is about ‘pre-planning’ the total time inside the OT is less and hence it is a big saving for any surgeon</p>
                    </div> 
                    { marketApproach != "market6" ? "" :

                    <div style={{position:'absolute',top:'-50px',left:'30%',width:'180px',height:'150px'}}>
                            <img src={require('../../images/ap6.jpg')}  style={{width:'100%',height:'100%',borderRadius:'20px'}} alt="" />
                    </div>
                    }
                </div>
            </div>

           

        </div>
    </div>
  )
}

export default TheProcess