import React, { useEffect, useLayoutEffect, useRef } from 'react';
import './cart2.css';
import { useNavigate } from 'react-router-dom';

const arthoPlan =[
  'Pre planning software',
  'Extramedullary Jig',
  'Open Platform for all company implants'
]
const arthroMr =[
  'Mixed Reality Platform',
  'Smart Glass (wearable device)',
  'Pre-Planning Software',
  'Extramedullary Jig',
  'Open platform for all company implants',
  'Real time surgical telecast-cloud based technology',
  'Equipped with 3D Trackers,Pointers, Verification paddle'
]

const Cart2 = ({loader}) => {
  const containerRef = useRef(null);

  const animateStackCards = () => {
    if (!containerRef.current) return;

    const top = containerRef.current.getBoundingClientRect().top;
    const offsetTop = 100;
    const cardHeight = 500;
    const marginY = 60;

    const cardItems = containerRef.current.getElementsByClassName('card');

    for (let i = 0; i < cardItems.length; i++) {
      const scrolling = offsetTop - top - i * (cardHeight + marginY);

      if (scrolling > 0) {
        const scale = (cardHeight - scrolling * 0.15) / cardHeight;
        const translateY = marginY *i ; 
        const cartScale = '0.8000'
       
        // cardItems[1].style.transform = `translateY(${80}px) scale(${0.9600})`; 
        if(scale > cartScale){
          cardItems[i].style.transform = `translateY(${translateY}px) scale(${scale})`; 
        cardItems[1].style.transform = `translateY(${30}%) scale(${1})`; 

        }else{ 
          cardItems[i].style.transform = `translateY(${translateY}px) scale(${0.8000})`; 
        cardItems[1].style.transform = `translateY(${30}%) scale(${1})`; 

        }

      }
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      animateStackCards();
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const chengRouts=(routs)=>{
    loader(true)
    setTimeout(()=>{
      navigate(routs)
    },500) 
  }
  return (
    <div>

      <div className="container"> 
        <div className="container card-deck-js" ref={containerRef}> 
        <div className="card row techCart1 mt-5" style={{ transform: 'translateY(0px)', boxShadow: '0px -22px 92px rgba(28, 32, 77, 0.35)' }}>
        <p className='display-2 fw-medium text-white techTitle col-11 m-auto' >Our Technological Platforms</p>
          
          <div className='col-md-6 col-12 cardUp' style={{ background: '#F5F5F5' }} onClick={() => chengRouts('/arthro3robo-plan')}>
              <div className='d-flex  justify-content-center' style={{ borderRadius: '100px 0 20px 0 20px' }}>
                  <div className='text-start col-11 m-auto mt-3'> 
                      <p className='fs-1 fw-bold' >Arthro3Robo Plan</p>
                      <ul> 
                      {
                        arthoPlan.map((el)=>{
                          return (
                            <li><p className='' style={{ color: 'gray' }}>{el}</p></li>
                          )
                        })
                      }
                      </ul>
                      <div style={{ height: '50px' }} className='mt-4'> 
                        <div className='buttonDiv' onClick={() => chengRouts('/arthro3robo-plan')}>
                          <div className='button'>Read More</div>
                          <div className='button-clip'>Read More</div>  
                        </div>
                      </div>
                        <br /><br />
                  </div>
              </div>
          </div>

          <div className='cardDown col-md-6 col-12 d-flex align-items-center justify-content-center' style={{ background: '#3E66DF' }} onClick={() => chengRouts('/arthro3robo-plan')}>
              <div className='col-8 d-flex align-items-center justify-content-center'>
                  <img src={require('../../images/tp2.webp')} style={{ height:'300px' }} alt="" />
              </div>
          </div>  
     
        </div> 

          <div className="card row techCart2 " style={{ transform: 'translateY(0px)', boxShadow: '0px -22px 92px rgba(28, 32, 77, 0.35)', }}>
          
            <div className='col-md-6 cardUp' style={{ background: '#F5F5F5' }} onClick={() => chengRouts('/arthro3robo-mr')} >
                <div className='d-flex  justify-content-center' >
                    <div className='text-start col-11 m-auto mt-3'> 
                        <p className='fs-1 fw-bold' >Arthro3Robo MR</p>
                        <ul> 
                          {
                            arthroMr.map((el)=>{
                              return (
                                <li><p className='' style={{ color: 'gray' }}>{el}</p></li>
                              )
                            })
                          }
                        </ul>
                        <div style={{ height: '50px' }} className='mt-4'> 
                          <div className='buttonDiv' onClick={() => chengRouts('/arthro3robo-mr')}>
                            <div className='button'>Read More</div>
                            <div className='button-clip'>Read More</div>  
                          </div>
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>

          <div className='col-md-6 cardDown d-flex align-items-center justify-content-center' style={{ background: '#3E66DF' }} onClick={() => chengRouts('/arthro3robo-mr')}>
              <div className='col-8 d-flex align-items-center'>
                  <img src={require('../../images/tp1.webp')} style={{ width: '100%' }} alt="" />
              </div>
          </div>  
     
        </div>

          
        </div>
 
      </div>
    </div>
  );
};

export default Cart2;
