import React, { useEffect} from 'react';
import ClipPath from './TextClip';
import PeopelClip from './PeopelClip'; 
import { Testimonials } from './MultiCarousel'; 
import MarketApproach from './TheProcess';
import UperFooter from '../UperFooter';
import WhyArthro from './Advantages';
import '../style.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Cart2 from './Cart2'; 
import { Link } from 'react-scroll';
 



const testimonials = [
  {
    text: "I have Personally used this novel technique on my patients. It’s a really innovative product which not only saves time by reducing few manual steps but also adds to accuracy of the surgery and ultimately the outcome. It is actually a “Computer-less navigation” in the operating room. I would recommend this technique to my fellow colleagues and you can rest assured of the services provided by their ground team.",
    name: "Dr. Pratul Jain  ",
    post: "Arthroplasty Surgeon, Stirling Hospital, Jaipur",
    img: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCkaWzfSwU5lJLnkM-1GaKhuYZZoE8slrp0hYZ78EYDreFf07Km1OqX73kkux43kqDptA&usqp=CAU`,
  },
  {
    text: "For the very first time, we used this software for one of my cases of complex primary Bilateral TKR with severe varus deformity and tibial bone defect. The software helped me in pre-deciding the cuts & implant sizes. so that I was quite relaxed performing the surgery.  The cuts we task were exactly what we have planned on the software & the kind of end result in terms of stability and alignment was unmatchable.  I will definitely use this software for my patients and recommend all my ortho colleagues to go for it.",
    name: "Dr. Karnav Panchal",
    post: "MS Orthro,  FAOS (South Korea)",
    img: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYnIGkzNeyRAJBj3rIs_vrOpgDUav9dmrMcRNWVDe97tk7OPFblj8USJAudGR5FeBjEUA&usqp=CAU`,
  },
  {
    text: "It was a great experience using the 3D Technique by Arthro 3D for Knee replacement for my patient. Got exact alignment without opening the femoral canal, Incision we don't have to extend & patient also recovered early & walked confidently very next day. It was all because of Arthro 3D software in which we got all the information regarding alignment & implant sizing. We got a perfect fitting of the implant as shown it in the software beforehand. All & all, it was the best knee replacement experience for me by 3D technique. I wish there will be a lot more perfect knee replacement done by this technique in the future.",
    name: "Dr. Shyam Sunder Soni ",
    post: "MS (Ortho) , MCh Orth (UK) Ranawat Joint Replacement Fellow (USA)",
    img:  `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhjprHgzAutQ-y0TyWF8AxO4xQPS3sCbRn-S5QynKgiCn89WPDQV7OeAcIwTVMEt34WPc&usqp=CAU`,
  },
];

const ourTestimonials = () => {
  let arr = [];
  for (let i = 0; i < testimonials.length; i++) {
    arr.push(
      <div class="testimonialsCard m-auto">
        <div class='d-flex justify-content-end w-100'>
          <br />  
        </div>
        <div class="text-start">
          <p style={{ fontSize: "16px" }}> {testimonials[i].text}</p>
        </div>

        <div class='d-flex align-items-center justify-content-between w-100'> 
          <div class="text-start">
            <p class="fw-bold" style={{ fontSize: "12px" }}>
              {testimonials[i].name}
            </p>
            <p class="text-secondary" style={{ fontSize: "10px" }}>
              {testimonials[i].post}
            </p>
          </div>
          <div class="text-start d-flex gap-1 align-items-center">
            <div
              class="bg-primary"
              style={{
                fontSize: "10px",
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <span class="text-white">
                <i class="bi bi-hand-thumbs-up-fill"></i>
              </span>{" "}
            </div>
            <span class="fw-bold" style={{ fontSize: "12px" }}>
              Testimonials
            </span>
          </div>
        </div>
        <div class="testimonialsImg bg-primary p-1">
          <img src={testimonials[i].img} style={{width:'100%',height:'100%',borderRadius:'50%',objectFit:'fill'}} alt="" />
        </div>
      </div>
    );
  }
  return arr;
};
 

const Home = ({data,loader}) => {   
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });  

  useEffect(()=>{
    const nav = document.querySelector('.circle');
    let lastScrollY = window.scrollY; 
    window.addEventListener('scroll',()=>{
        if(window.scrollY > 50){
             nav.style.display = 'none';
        }else{
          nav.style.display = 'flex';
 
        }
    }) 
  })

  return (
    <div  class='home' > 
          <div> 
            <div class='homeDr' style={{position:'relative'}}  > 
                <div class='dr'  >
                    <img src={require('../../images/dr.webp')} alt="" /> 
                </div> 
                <div class='circle' > 
                  <div style={{ position: "relative" }}>
                    <button class='btn  rounded-5 d-flex align-items-center justify-content-center ' style={{background:'#333B8F',width:'40px',height:'40px'}}><i class="bi bi-arrow-down fs-5 text-white downLogo"></i></button>
                  </div>
               

                </div>

                <div style={{position:'absolute',top:'0',width:'100%'}} > 
                  <div style={{height:'100vh' }} className="d-flex align-items-center">
                    <div className='rounded-5 m-auto col-12 text-start row flex-column-reverse flex-md-row'>
                        <div class='col-sm-11 col-12 m-auto'> 
                          <p className='fw-medium titleText' style={{ color:'#D5D8FF'}}>Humanoid</p>
                          <p className='fw-medium titleText' style={{ color:'#D5D8FF'}}>
                            <span style={{color:'red',fontWeight:700}}>Robotics</span> in Joint
                          </p>
                          <p className='titleText fw-medium' style={{color:'#D5D8FF'}}>replacement</p> <br />
                        </div>
                    </div>
                  </div> 

                  <div class='mt-5 textId'> 
                    <ClipPath/>  
                  </div> 
                </div> 

                  
            </div>


            <div class='my-5' > 
              <PeopelClip/>
            </div>

           

            <div class=' my-5'>
              <br /><br />
              <Cart2 loader={loader}/>
            </div> 
            <br /><br />
          </div>  
          
          <div class='mt-5'>
              <br />
              <MarketApproach/>
            </div>

            <div>
            <WhyArthro/>
            </div>

            <div class=' col-11 m-auto'>
              <br />
              <div > 
                <p class='display-2 fw-medium text-white col-11 m-auto ' data-aos="fade-up" >Customer Success Stories</p>
                {/* <p class='col-lg-5 col-md-8  col-11 m-auto mt-3' style={{color:'#66707A'}} data-aos="fade-up">Hear from our satisfied B2B clients and learn how our optical machines have transformed their businesses.</p> */}
              </div>
              <div class='mt-5'>
                <Testimonials data={ourTestimonials()}/>
              </div> 
            </div> 

            

            <div  style={{ background: 'linear-gradient(transparent 0%, transparent 50%,#0A0B18 50% )'}}>
              <UperFooter data={data}/>
            </div> 

     </div>
  );
};

export default Home;
