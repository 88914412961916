 
import './App.css';
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/home/Home'; 
import RegisterUs from './components/RegisterUs'; 

const About = React.lazy(()=>import('./components/About'))
const Arthro_MR = React.lazy(()=>import('./components/ArthroMr/Arthro_MR'))
const Arthro_Plan = React.lazy(()=>import('./components/ArthroMrPlan/Arthro_Plan'))
const FAQ = React.lazy(()=>import('./components/FAQ'))
const Contact = React.lazy(()=>import('./components/Contact'))
const SurgeonCenter = React.lazy(()=>import('./components/SurgeonCenter'))
const RequestForDemo = React.lazy(()=>import('./components/RequestForDemo'))
const Hip3Wiz_Mr = React.lazy(()=>import('./components/ArthroMr/Hip3Wiz_Mr'))
const Kne3Wiz_Mr = React.lazy(()=>import('./components/ArthroMr/Kne3Wiz_Mr'))
const Glen3Wiz_Mr = React.lazy(()=>import('./components/ArthroMr/Glen3Wiz_Mr'))
const Hip3Wiz_Plan = React.lazy(()=>import('./components/ArthroMrPlan/Hip3Wiz_Plan'))
const Kne3Wiz_Plan = React.lazy(()=>import('./components/ArthroMrPlan/Kne3Wiz_Plan'))
const Glen3Wiz_Plan = React.lazy(()=>import('./components/ArthroMrPlan/Glen3Wiz_Plan'))




 
 
 

function App() { 
  // const [popup, setPopup] = useState(true)
  const { pathname, hash, key } = useLocation();
  const [loader, setLoader] = useState(true)
  const [quire,setQuire] = useState() 
  const [show, setShow] = useState()
  const [demoQuire,setDemoQuire] = useState()  
  const [demoShow, setDemoShow] = useState()
  const location = useLocation();  
  const loca = location.pathname 


  

  useEffect(()=>{
    const loderDiv = document.querySelector('.routsLoder') 
    const loder1 = document.querySelector('.routs')  
    if(loader){
      loderDiv.classList.add('active')
      loder1.style.transition = '2s'  
      loder1.classList.add('activeRouts'); 
      setTimeout(() => {  
        loderDiv.classList.remove('active')  
        setLoader(false)   
      },500) 
      setTimeout(() => { 
        loder1.style.transition = '0s' 
        loder1.classList.remove('activeRouts'); 
      },1000) 
    }
  },[loader]) 

  useEffect(()=>{
    setShow(quire)
  },[quire]) 

  useEffect(()=>{
    setDemoShow(demoQuire)
  },[demoQuire]) 
  

  useEffect(() => { 
    if (hash === '') {
      window.scrollTo(0, 0);
    } 
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);  


  // setTimeout(()=>{
  //   setPopup(!popup)
  // },300000)
  return (
    <div className="App" style={{position:'relative'}}>
      <Navbar data={setQuire} loader={setLoader}/> 
      {show ? <RegisterUs data={setQuire}/> : ''}

      {demoShow ? <RequestForDemo demoData={setDemoQuire}/> : ''} 
    
      
      
        <Suspense fallback={<div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}> <div class=''><img src={require('./images/artho-logo.png')} style={{width:'100%'}}/></div> </div>}> 
            <Routes> 
              <Route path='/' element={<Home data={setQuire} loader={setLoader}/>}/>
              <Route path='/about' element={<About data={setQuire} loader={setLoader}/>}/> 
              <Route path='/contact' element={ <Contact data={setQuire}/>}/>  
              <Route path='/faqs' element={ <FAQ data={setQuire}/>}/> 
              <Route path='/surgeon-center' element={ <SurgeonCenter data={setQuire}  demoData={setDemoQuire} loader={setLoader}/>}/>
              {/* <Route path='/request-demo' element={ <RequestForDemo data={setQuire} loader={setLoader}/>}/>   */}

              <Route path='/arthro3robo-mr' element={ <Arthro_MR data={setQuire}  loader={setLoader}/>}/>
              <Route path='/hip3wiz-mr' element={ <Hip3Wiz_Mr data={setQuire} loader={setLoader}/> }/>  
              <Route path='/kne3wiz-mr' element={ <Kne3Wiz_Mr data={setQuire} loader={setLoader}/>}/>  
              <Route path='/glen3wiz-mr' element={ <Glen3Wiz_Mr data={setQuire} loader={setLoader}/>}/>  

              <Route path='/arthro3robo-plan' element={ <Arthro_Plan data={setQuire} loader={setLoader}/>}/>  
              <Route path='/hip3wiz-plan' element={ <Hip3Wiz_Plan data={setQuire} loader={setLoader}/> }/>  
              <Route path='/kne3wiz-plan' element={ <Kne3Wiz_Plan data={setQuire} loader={setLoader}/>}/>  
              <Route path='/glen3wiz-plan' element={ <Glen3Wiz_Plan data={setQuire} loader={setLoader}/>}/>  

            </Routes>
        </Suspense>
    
      <Footer loader={setLoader}/>
       
      
      <div class='routsLoder'>
          <div class='routs'>
            <img src={require('./images/routs.webp')} style={{width:'100%',height:'100%'}}/>
          </div>
      </div>
{/* {
  popup &&
      <div class='popup'>
          <div class='col-lg-9 col-11 text-end'>
            <p style={{cursor:'pointer'}} onClick={()=>setPopup(false)} ><i class="bi bi-x-circle text-white fs-4 my-3 "></i></p>
            <img src={require('./images/popup.jpg')} style={{width:'100%'}} alt="" />
          </div>
      </div>
} */}


    </div>
  );
}

export default App;
