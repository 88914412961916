import axios from 'axios';
import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io';
import Swal from 'sweetalert2';

const RegisterUs = ({data}) => {

    const [register,setRegister] = useState({})

    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7); 
    const minDate = sevenDaysFromNow.toISOString().split('T')[0];

    
  const registerInfo = (e)=>{
    const {name,value}= e.target;
    setRegister({...register,[name]:value})
}

const sendRegisterDetails =()=>{
    if(register?.name && register?.name != "" &&
    register?.phone && register?.phone != "" &&
    register?.email && register?.email != "" &&
    register?.date && register?.date != "" 
    // && register?.subject && register?.date != ""
     ){ 
            axios.post(`https://hi-labsolution.net/api/arthro-register.php`,register)
            .then((res)=>{ 
                if(res.data.responseStatus == true){
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: `${res.data.responseMessage}`,
                      showConfirmButton: false,
                      timer: 2000,
                    })
                    setRegister({
                        "name" : '',
                        'phone':'',
                        'email': '',
                        'date':'',
                        // 'subject':'',
                        'message':'' 
                    })
                }else{
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `${res.data.responseMessage}`,
                        showConfirmButton: false,
                        timer: 2000,
                      })
                }
            }).catch((err)=>{
                console.log(err);
            })
        
    }else{
        Swal.fire({
            position: "center",
            icon: "error",
            title: `All fields must be required!`,
            showConfirmButton: false,
            timer: 2000,
          })
    }
  } 


  return (
    <div style={{width:'100%',height:'100vh',position:'fixed',top:'0',background:'rgba(0,0,0,.5)',zIndex:'2',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div class='col-lg-8 col-11 m-auto' style={{height:'95vh',overflowY:'auto',background:'#0A0B18'}}>
            <div class='d-flex justify-content-end'>
                <p class='text-white fs-3 p-0 px-5' style={{cursor:'pointer'}} onClick={() => data(false)}>  <IoMdClose/> </p>
            </div>
            <div class='col-11 m-auto mb-5'>
                <p class='fs-1 fw-medium' style={{color:'#D5D8FF'}}>Register for Demonstration
</p>
                <p class='my-4 text-start form-lable'>Please fill the form our team will contact you!</p>
                <div class='col-md-12 row mt-3 text-start'>
                        <div class='col-md-6 mt-3'>
                            <label htmlFor=""  class='form-lable' >Full Name*</label>
                            <input type="text" class='form-control p-2 px-3 mt-2' name='name' value={register?.name} onChange={registerInfo} style={{background:'transparent' }} />
                        </div>
                        {/* <div class='col-md-6 mt-3'>
                            <label htmlFor=""  class='form-lable' >Subject*</label>
                            <input type="text" class='form-control p-2 px-3 mt-2' name='subject' value={register?.subject} onChange={registerInfo} style={{background:'transparent'}} />
                        </div> */}
                        <div class='col-md-6 mt-3'>
                            <label htmlFor=""  class='form-lable' >Email*</label>
                            <input type="text" class='form-control p-2 px-3 mt-2' name='email' value={register?.email} onChange={registerInfo} style={{background:'transparent'}} />
                        </div>
                        <div class='col-md-6 mt-3'>
                            <label htmlFor=""  class='form-lable' >Phone Number*</label>
                            <input type="text" class='form-control p-2 px-3 mt-2' name='phone' value={register?.phone} onChange={registerInfo} style={{background:'transparent'}} />
                        </div>

                        <div class='col-md-6 mt-3 ' style={{position:'relative'}}>
                          <label htmlFor="" for="date"  class='form-lable' >Select Preferred Date*</label>
                        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'end'}}>
                             
                            <p style={{marginTop:'14px',fontSize:'15px',marginRight:'20px'}} ><i class="bi bi-calendar-fill form-lable"></i></p>
                        </div>
                        <div style={{position:'absolute',top:'20px',width:'94%'}}> 
                        <input type="date" id='date' class='form-control p-2 px-3  mt-2' min={minDate} name='date' value={register?.date} onChange={registerInfo} style={{background:'transparent'}}/>
                        </div>
                    </div>
                        
                        <div class='col-12 mt-4'>
                            <label htmlFor=""  class='form-lable' >Your Message*</label>
                            <textarea  id="" rows="2" class='form-control mt-2' name='message' value={register?.message} onChange={registerInfo} style={{background:'transparent'}}></textarea>
                        </div>
                        <div class='d-flex justify-content-end mt-4'>
                            <div class='buttonRedDiv mb-5' onClick={sendRegisterDetails}>
                                <div class='buttonRed'>Submit</div>
                                <div class='buttonRed-clip'>Submit</div>  
                            </div>
                        </div>
                    </div>
            </div>

        </div>
    </div>
  )
}

export default RegisterUs