import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


export const Testimonials = ({ data }) => {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1120 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1120, min: 850 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 850, min: 0 },
        items: 1,
      },
    };
    return (
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={false}
        arrows={true}
        autoPlaySpeed={2500}
        showDots={false}
      >
        {data?.map((el) => {
          return (
            <div class='m-auto'>
                <br />
                <br />
              {el}
              
            </div>
          );
        })}
      </Carousel>
    );
  };