import React, { useEffect } from 'react'
import '.././style.css'
import AOS from "aos";
import "aos/dist/aos.css";

const Advantages = () => {
    useEffect(() => {
        AOS.init({ duration: 1500 });
      });
  return (
    <div>
        <div class='advantagesPc'> 
        <div>
            <br /><br />  
            <div class='col-11 m-auto  row justify-content-between '>
                <div class='col-2' data-aos="fade-up" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Pre- planning software </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class='col-2' data-aos="fade-up" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Lesser OT time</p>
                            </div>
                        </div>
                    </div>

                </div> 
                <div class='col-2' data-aos="fade-up" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Supports all  company implants </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class='col-2' data-aos="fade-up" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Extramedullary surgical plan</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class='col-2' data-aos="fade-up" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Better implant inventory management</p>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>

            <div data-aos="zoom-in" > 
                <p class='display-1 fw-bold whyArthoText'>   <span class='text-primary'>Advantages</span></p>
            </div>

            <div class='col-11 m-auto row justify-content-between '>
                <div class='col-2' data-aos="fade-down" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Patient specific reusable Jig </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class='col-2' data-aos="fade-down" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Less foot print in OT</p>
                            </div>
                        </div>
                    </div>

                </div> 
                <div class='col-2' data-aos="fade-down" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Wearable smart glass </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class='col-2' data-aos="fade-down" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2  fw-bold '>Live surgery video cast </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class='col-2' data-aos="fade-down" >
                    <div class='col-11 d-flex m-auto h-100'>
                        <div>
                            <div style={{height:'100%',width:'1px',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div>
                        <div class='text-start'>
                            <div class='m-2'> 
                                <p class='text-white my-2 fw-bold'>Less <br /> capital  investment</p>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
            <br /><br />
            
        </div>
        </div>

        <div class='advantagesMb'> 
            <div class='row col-11 m-auto '>
                
                <div class='col-5 d-grid align-content-around'>
                    <div data-aos="fade-left"> 
                        <div class='m-2 text-start'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Pre- planning software </p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div> 
                    </div> 
 
                    <div data-aos="fade-left"> 
                        <div class='m-2 text-start'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Lesser OT time</p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div> 
                    </div>

                    <div data-aos="fade-left"> 
                        <div class='m-2 text-start'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Supports all company implants </p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div> 
                    </div>

                    <div data-aos="fade-left"> 
                        <div class='m-2 text-start'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Extramedullary surgical plan</p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div> 
                    </div>

                    <div data-aos="fade-left"> 
                        <div class='m-2 text-start'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Better implant inventory management </p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                        </div> 
                    </div>
                </div>
                <div class='col-2 d-grid justify-content-center'>
                    <br /><br />
                    <p class='display-2 text-primary fw-bold' data-aos="fade-down">A</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">D</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">V</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">A</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">N</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">T</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">A</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">G</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">E</p>
                    <p class='display-2 text-primary fw-bold' data-aos="fade-up">S</p>
                    <br /><br />
                </div>
                <div class='col-5 d-grid  align-content-around'>
                    <div data-aos="fade-right"> 
                        <div class='m-2 text-end'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Patient specific reusable Jig </p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div> 
                    </div> 

                    <div data-aos="fade-right"> 
                        <div class='m-2 text-end'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Less foot print in OT</p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div> 
                    </div> 

                    <div data-aos="fade-right"> 
                        <div class='m-2 text-end'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Wearable smart glass </p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div> 
                    </div> 

                    <div data-aos="fade-right"> 
                        <div class='m-2 text-end'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Live surgery video cast </p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div> 
                    </div> 

                    <div data-aos="fade-right"> 
                        <div class='m-2 text-end'>
                            <p class='text-white my-2' style={{fontSize:'12px'}}>Less capital investment</p>
                        </div>
                        <div class='d-flex align-items-center'>
                            <div style={{width:'100%',border:'1px solid white',marginLeft:'3px'}}></div>
                            <div style={{width:'10px',height:'10px',borderRadius:'20px',background:'red'}}></div>
                        </div> 
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default Advantages